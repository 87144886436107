var site = site || {};
var generic = generic || {};
site.account = site.account || {};
site.signin = site.signin || {};
site.userInfoCookie = site.userInfoCookie || {};
page_data = page_data || {};
site.userInfoCookie.getValue = site.userInfoCookie.getValue || function() { return ''; };
var settings = Drupal.settings || {};
var settingsLoyalty = typeof settings.loyalty === 'object' ? settings.loyalty : {};
var settingsLoyaltyRegistrationSigninPage = settingsLoyalty.registration_signin_page || {};
var hasIAM = Drupal.settings && Drupal.settings.iam_signin;
var iamData;
(function ($) {
  Drupal.behaviors.signIn = {

    bindIamSignin: function () {
      generic.jsonrpc.fetch({
        method: 'vulcan.getConfig',
        params: [{ section: 'iam' }],
        async: false,
        onBoth: function (jsonRpcResponse) {
          iamData = jsonRpcResponse.getValue();
          if (iamData && iamData.enabled && iamData.redirect_url) {
            window.location = iamData.redirect_url;
          } else {
            window.location = '/account/signin.tmpl';
          }
        }
      });
    },

    attach: function (context, settings) {
      var self = this;
      Drupal.ELB.loadPersistenUserCookie();
      var signedIn = site.userInfoCookie.getValue('signed_in');
      var forceReturn = false;
      var returnURL = null;
      if (signedIn == '0') signedIn = false;
      var colorboxWidthValidate = Drupal.settings.global_js_variables.colorbox_width_validate;
      var colorboxMobileValidate = Drupal.settings.global_js_variables.colorbox_mobile_validate;
      var colorboxWidthValue = Drupal.settings.global_js_variables.colorbox_width_value ? Drupal.settings.global_js_variables.colorbox_width_value : '';
      var editColorboxParameters = Drupal.settings.globals_variables.pass_extra_params_to_colorbox || 0;
      var bdayPopupRemoveSigninForm =  Drupal.settings.bday_popup_remove_signin_form || 0;
      if (colorboxWidthValidate) {
        editColorboxParameters = Drupal.settings.global_js_variables.pass_params_to_colorbox || 0;
      }
      var colorboxParm = $('.page-navigation', context).offset();
      var colorboxParmWidth = $('.page-utilities__search', context).width() + $('.page-utilities__cart', context).width() + 20;
      var controlOverlay = Drupal.settings.globals_variables.controlling_colorbox || 0;
      // Determine if user has signed in before by looking at the persistent
      // user cookie.
      var persistentCookie = Drupal.ELB.getJSONCookie('persistent_user_cookie');
      var loyalty = persistentCookie.is_loyalty_member == "1";
      var controlWraperDiv = Drupal.settings.globals_variables.controlling_wrapper_div || 0;

      // @TODO: get email address too so we can put it in the input field
      var firstName = persistentCookie.first_name || '';
      firstName = firstName.replace(/\+/g, ' ');
      var firstTime = persistentCookie.first_time;
      var userDetected = !!firstName;

      // Show/hide registration/sign in links on Beaty Feed page based on user detection
      if (userDetected) {
        $('.bfeed-create-account').hide();
        $('.bfeed-sign-in').show();
      } else {
        $('.bfeed-create-account').show();
        $('.bfeed-sign-in').hide();
      }

      var $overlayTemplate = $('#signin-overlay-template', context);
      var overlayContent = $overlayTemplate.html();
      // replaced page-utilities__account-button with page-utilities__signin-text
      //
      if (controlOverlay) {
        var $pageredirection = $('.page-utilities__signin-text, .js-launch-account, .field-mobile-menu .sign-in---my-account, .loyalty_join_signin .form-submit:last-child', context);
        $pageredirection.on('click', function(event) {
          event.stopImmediatePropagation();
          if (hasIAM) {
            self.bindIamSignin();
          } else {
            window.location.href = '/account/signin.tmpl';
          }
        });
      }
      var $triggerButton = $('.page-utilities__signin-text, .js-launch-account, .field-mobile-menu .sign-in---my-account, .loyalty_join_signin .form-submit:last-child', context);

      // Determine the state of the overlay to show:
      var signInOverlayState = $.cookie('signInOverlayState', {path: '/'});
      // Delete the cookie immediately (apparently these are both needed?):
      $.cookie('signInOverlayState', null, {path: '/'});
      $.cookie('signInOverlayState', null);

      var isMobile = site.client.isMobile;
      var $signInPage = $('.sign-in-page', context);
      var isSignInPage = $signInPage.length;
      var colorboxSettings = {
        html: overlayContent,
        className: 'signin-overlay-wrapper signin-overlay-loyalty',
        fixed: true,
        maxWidth: '1022px',
      };
      if (isMobile) {
        colorboxSettings.height = '1000px';
      }
      if(colorboxWidthValue) {
        colorboxSettings.width = colorboxWidthValue;
      }
      if (editColorboxParameters) {
        colorboxSettings.left = 0;
        colorboxSettings.right = colorboxParmWidth ? colorboxParmWidth : '';
        colorboxSettings.top = colorboxParm ? colorboxParm.top : '';
      }
      // User greeting and login/logout link below Account button
      // top SIGN IN or SIGN OUT section
      var userLoginState = $('.user-login-state');
      var $accountText = $('.page-utilities__account-text');
      var $loyaltyTermsCheckbox = $('.js-loyalty-required-terms', context);
      var $loyaltyActiveFlag = $('.js-loyalty-required-loyalty-flag', context);
      var $gdprInfoLink = $('.loyalty_join_signin .info-link-gdpr', context);
      var userCartCookie = $.cookie("FE_USER_CART");

      if (userLoginState.length) {
        if (signedIn) {
          if (isMobile) {
            if (settings.ajaxPageState.theme === 'de_mobile') {
              $('.js-is_signed_in', $accountText).removeClass('hidden');
              $('.user-greeting', $accountText).addClass('hidden');
            }
            if (settingsLoyaltyRegistrationSigninPage.il_rollout) {
              $('.loyalty_mrkt_ldng .js-sign-in-popup').hide();
            }
          } else { // Implied !isMobile
            // user is signed in so show the sign out link
            // "Hi [name!] Sign Out"
            $accountText.show();
            $('.js-is_signed_in', $accountText).removeClass('hidden');
            $('.page-utilities__signin-text').hide();
            $('.loyalty_mrkt_ldng .js-sign-in-popup').hide();
            $loyaltyTermsCheckbox.removeClass('hidden');
            $loyaltyActiveFlag.removeClass('hidden');
          }
        } else if (!isMobile) { // Implied !signedIn
          if (signedIn && (userDetected || loyalty)) {
            // user is signed out but detected show
            // "Hi [name!] Not You?"
            $accountText.show();
            $('.js-is_recognized', $accountText).removeClass('hidden');
            $('.page-utilities__signin-text').hide();
            $('.loyalty_mrkt_ldng .js-sign-in-popup').hide();
            $loyaltyTermsCheckbox.removeClass('hidden');
            $loyaltyActiveFlag.removeClass('hidden');
          } else {
            // user is signed out so show the sign in link
            // "Sign In"
            $('.page-utilities__signin-text').show();
            $('.page-utilities__account-text').hide();
            $('.loyalty_mrkt_ldng .js-sign-in-popup').show();
            $loyaltyTermsCheckbox.addClass('hidden');
            $loyaltyActiveFlag.addClass('hidden');
            $gdprInfoLink.addClass('hidden');
          }
        } else { // Implied !signedIn and isMobile
          $('.loyalty_market .loyalty_market__btn__signin').css('display', 'block');
        }
      }

      // Show JOIN LOYALTY or USERNAME + POINTS
      var userLoyaltyState = $('.user-loyalty-state');
      if( userLoyaltyState.length ) {
        if (!isMobile) {
          if (signedIn && loyalty) {
            userCartCookie = userCartCookie?.split('&').map(item => item.split(':')) || userCartCookie;
            var loyaltyLevelName = (userCartCookie?.find((item) => item[0] === 'loyalty_level_name')?.[1] || '')?.replace(/\+/g, ' ');
            var current_available_points = userCartCookie?.find(item => item[0] === 'current_available');
            var points_current = 0;
            if(Array.isArray(current_available_points)){
              points_current = current_available_points[1];
            }
            // add the points/tier name to the global navigation under the user name
            var points = persistentCookie.points || 0;
            var levelName = persistentCookie.loyalty_level_name || '';
            levelName = levelName.replace(/\+/g, ' ');

            $(userLoyaltyState).find('.user-logged-in').find('.js-loyalty-points-value').html(points_current || points);
            $(userLoyaltyState).find('.user-logged-in').find('.js-loyalty-tier-name').html(loyaltyLevelName || levelName);

            // show points
            $('.user-logged-in').show();
            $('.user-logged-out').hide();
          } else {
            // show join link
            $('.user-logged-out').show();
            $('.user-logged-in').hide();
          }
        }

        // bind the click so when the user clicks 'join e-list' they are shown the popup to enroll
        $('.js-join-elist').click(function(event) {
          event.preventDefault();
          $(document).trigger('loyalty.show_signup_form', [{joinNowButtonClicked: true}]);
        });
      }

      // Cursor issue on IOS11.X
      function _launchOverlay(forceReturn, returnURL) {
        var $body = $('html');
        if (generic.env.isiOS11) {
          colorboxSettings.onComplete = function() {
            $body.addClass('body__fixed');
          };
          colorboxSettings.onClosed = function() {
            $body.removeClass('body__fixed');
          };
        }
        if (bdayPopupRemoveSigninForm) {
          colorboxSettings.onComplete = function() {
            $('#colorbox .sign-in-component__form.sign-in-component__form--sign-in').remove();
          };
        }
        $.colorbox(colorboxSettings);

        var $overlay = $('.signin-overlay-wrapper .sign-in-component');

        // Redirect back to the current page
        // var returnURL = '?RETURN_URL=' + window.location.pathname;
        // Generally only registration sends you back to your last page, but
        // there are some cases where sign in can (ie. "Save to Profile" in the
        // Foundation Finder)
        if (forceReturn) {
          $('form', $overlay).each(function(){
            if (!$('input[name=RETURN_URL]', this).length) {
              $(this).append('<input type="hidden" name="RETURN_URL" value="" />');
            }
          });
        }
        returnURL = returnURL || window.location.pathname + window.location.search;

        $('input[name=RETURN_URL]', $overlay).val(returnURL);

        _initForm($overlay);
        site.account.initPasswordValidationTooltip();

        // Init selectboxes for desktop:
        if (!isMobile) {
          $('.selectbox', $overlay).selectBox();
          // Apply global js text input behavior:
          if (typeof Drupal.behaviors.formTextInputs !== "undefined") {
            Drupal.behaviors.formTextInputs.attach($('.signin-overlay-wrapper'));
          }
        }
      }

      function triggerOverlay(event, element) {
        event.preventDefault();

        var $element = $(element);
        var forceReturn = $element.hasClass('js-launch-account--return');
        var returnURL = forceReturn ? $element.attr('data-return-url') : null;
        var $signInComponent = $('.sign-in-component', context);

        // If already signed in, this button works as a link to the account
        // landing.
        if (signedIn) {
          window.location = '/account/index.tmpl';
        } else if ($signInComponent.length) {
          //show the sign in form if you clicked on the SIGN IN link
          event.currentTarget.classList.contains('page-utilities__signin-text') && $signInComponent.addClass('sign-in');
          // If the form is already on the page, focus on the first element in it
          $signInComponent.find('.form-text:visible').first().focus();
        } else if (settings.sign_in_redirection) {
          window.location = '/account/signin.tmpl';
        } else {
          _launchOverlay(forceReturn, returnURL);
          if(generic.env.isIOS4) {
             popupScroll.destroy();
             popupScroll = null;
             popupScroll = new IScroll('#colorbox',  { mouseWheel: true });
             setTimeout( function(){
               popupScroll.refresh();
             }, 500 ) ;
          }
        }
      }

      function _initForm($wrapper) {
        $('.sign-in-component', $signInPage).show();
        var $registerForm = $('.sign-in-component__form--registration', $wrapper);
        var $registerConfirmForm = $('.sign-in-component__confirm--registration', $wrapper);
        var $signInForm = $('.sign-in-component__form--sign-in', $wrapper);
        var $showPass = $('input[name=SHOW_PASSWORD]', $wrapper);
        var $pass = $('input[type=password]', $wrapper);
        var $error_messages_list = $("ul.error_messages").find('li');
        var $mobilePhoneWrapper = $('.js-mobile_phone-wrapper', $wrapper);
        var $smsPromotionsCheckbox = $('.js-sms_promotions-checkbox', $wrapper);
        var $csrfTokenTag = $('input[name="_TOKEN"]', $registerForm);
        var csrfToken = $.cookie('csrftoken') || '';
        var windowLocationHref =  window.location.href;
        var forceDisplayRegisterForm = windowLocationHref.indexOf('register_now=1') !== -1;
        var forceDisplaySignInForm = windowLocationHref.indexOf('sign_in=1') !== -1 && !$wrapper.hasClass('sign-in');
        var $forms = $('form', $wrapper);
        var feUserCartCookie;
        var signedInValue;
        var registrationFormName;
        var $form = {};

        $forms.on('submit', function () {
          $form = $(this);
          feUserCartCookie = $.cookie('FE_USER_CART');
          feUserCartCookie = feUserCartCookie?.split('&').map((item) => item.split(':')) || feUserCartCookie;
          signedInValue = feUserCartCookie?.find((item) => item[0] === 'signed_in');
          registrationFormName = $('input[name="_SUBMIT"]', $form).val();
          if (registrationFormName === 'registration_confirm' && parseInt(signedInValue[1]) === 0) {
            window.location.href = '/account/signin.tmpl?_SUBMIT=signout';

            return false;
          }
        });

        // Based on window location href & additonal query param
        // Decide if the section displayes the register or the sign in content
        if (forceDisplayRegisterForm) {
          $wrapper.removeClass('sign-in');
        } else if (forceDisplaySignInForm) {
          $wrapper.addClass('sign-in');
        }

        // Set the appropriate class on the outer container to tell css what to
        // display. By default we show the registration
        // form, but if the overlay state cookie indicates we just registered or
        // signed in, we show the relevant confirmation screen instead. Finally,
        // if the user's ever logged in on this machine we display the sign in
        // form by default.

        // First off, if there's an error in the form, and we're trying to show
        // a confirmation page, go back a step:
        if (isSignInPage && $('input.error, select.error', $wrapper).length) {
          if (signInOverlayState == 'register-confirm') {
            signInOverlayState = 'register';
          } else if (signInOverlayState == 'signin-confirm') {
            signInOverlayState = 'signin';
          }
        }

        if(isSignInPage && $('#account_lockout\\.\\.').is(":visible")){
          if (signInOverlayState == 'signin-confirm') {
            signInOverlayState = 'signin';
          }
        }

        // Toggle the class:
        if (signInOverlayState == 'register' || signInOverlayState == null) {
          if (settingsLoyaltyRegistrationSigninPage.il_rollout) {
            $wrapper.removeClass('sign-in');
            if ($wrapper.find('#form--registration--field--SMS_PROMOTIONS').prop('checked')) {
              $wrapper.find('.sign-in-component__phone-wrapper').removeClass('hidden');
            }
          }
          $wrapper.addClass('registration');
        } else if (signInOverlayState == 'register-confirm') {
          $wrapper.addClass('registration-confirmation');
        } else if (signInOverlayState == 'signin-confirm') {
          // $wrapper.addClass('sign-in-confirmation');
        } else if (signInOverlayState == 'signin' || userDetected) {
          $wrapper.addClass('sign-in');
          $.cookie('showsignin', null, {path: '/'});
        }

        // if user has registered before then show sign in form
        if ((firstTime == 0) && (signInOverlayState != 'register-confirm') && (userDetected)) {
          $wrapper.addClass('sign-in');
        }

        if(signInOverlayState == 'signin-confirm' && !loyalty) {
          $wrapper.addClass('signin-join-loyalty');
        }

        // Remove any stray error classes that may have ended up on the hidden forms:
        $('form:hidden', $wrapper).find('input.error, select.error').removeClass('error');

        // Preprocess the form:
        if (!(controlWraperDiv)) {
          $pass.each (function () {
            $(this).after('<div class="visible-pass-wrapper"><input class="visible-pass form-text" type="text" style="display: none;" /></div>');
            if (!isMobile) {
              if (typeof Drupal.behaviors.formTextInputs !== 'undefined') {
                Drupal.behaviors.formTextInputs.attach($('.visible-pass-wrapper'));
              }
            }
          });
          var $visiblePass = $('.visible-pass', $wrapper);
        }

        // Add the user's first name to the sign in confirmation screen header:
        if (firstName) {
          var $signInConfirmHeader = $('.sign-in-component__confirm--sign-in .sign-in-component__header', $wrapper);
          $signInConfirmHeader.text($signInConfirmHeader.text().replace('first_name', firstName));
        }

        if ($('.sign-in-component__fpw-link', $wrapper).length >0){
          site.signin.forgotPassword({
            resetPassword: true,
            emailNode: $("input#sign-in-component__EMAIL_ADDRESS", $wrapper),
            errorListNode: $(".signin-block__lost-pass-text", $wrapper),
            forgotPasswordLink: $('#forgot-password', $wrapper),
            forgotPasswordNote: $('p#forgot_pw_note', $wrapper)
          });
          if (typeof site.signin.forgotPasswordHint === 'function') {
            site.signin.forgotPasswordHint({
              resetPassword: true,
              emailNode: $('input#sign-in-component__EMAIL_ADDRESS', $wrapper),
              errorListNode: $('.signin-block__lost-pass-text', $wrapper),
              forgotPasswordLinkHint: $('#forgot-pass', $wrapper),
              forgotPasswordNoteHint: $('p#forgot_pw_note', $wrapper)
            });
          }
        }

        // Bind events:
        if (!(controlWraperDiv)) {
          $showPass.on('change', function (e) {
            var $labelEl = $(this).next('label');
            var show_text = $labelEl.attr('data-show-text');
            var hide_text = $labelEl.attr('data-hide-text');
            var showing = $(this).is(':checked');
            $visiblePass.add($pass).toggle();
            if (showing) {
              $('.visible-pass', $wrapper).each(function () {
                $(this).val($(this).parent().prev().val()).trigger('blur');
                hide_text && $labelEl.text(hide_text);
              });
            } else {
              $pass.each(function () {
                $(this).val($(this).next().children().first().val()).trigger('blur');
                show_text && $labelEl.text(show_text);
              });
            }
          });
        }

        $smsPromotionsCheckbox.on('change', function () {
          if ($smsPromotionsCheckbox.prop('checked')) {
            $mobilePhoneWrapper.removeClass('hidden');
          } else {
            $mobilePhoneWrapper.addClass('hidden');
          }
        });

        // Prevent the sms form from submitting on the register-confirm overlay when the mobile number is blank
        if (signInOverlayState == 'register-confirm') {
          $('.sign-in-component__confirm-options, input[type=submit]').on('click', function() {
            var mobileNumber = $('#sign-in-component__SMSPROMO_MOBILE_NUMBER');
            if ( $(mobileNumber).attr('value') == '') {
              $('input[type=hidden], [name=_SECONDARY_SUBMIT], [value=sms]').remove();
            }
            $('.sign-in-component__confirm-options, input[name=RETURN_URL]').val('/account/registration.tmpl');
          });
        }

        if (signInOverlayState == 'signin-confirm') {
          // join loyalty
          $('.signin-loyalty-cta__button', $wrapper).off().click(function(event) {
            event.preventDefault();
            var params = {};
            params['_SUBMIT'] = 'loyalty_email_signup';
            params['LOYALTY_ACTIVE_FLAG'] = '1';
            params['PC_EMAIL_ADDRESS'] = site.userInfoCookie.getValue('email');
            params['ACCEPTED_LOYALTY_TERMS'] = '1';
            if (Drupal.settings.globals_variables.set_site_terms) {
              params['ACCEPTED_PRIVACY_POLICY'] = '1';
            }
            var field = $('.signin-loyalty-cta input[name="ACCEPTED_LOYALTY_TERMS"]'), undefined;
            if (field != undefined && field.length > 0) {
              var isUncheckedBox = field.is(':checkbox') && !field.prop('checked');
              params['ACCEPTED_LOYALTY_TERMS'] = isUncheckedBox ? '' : field.val();
              if (field.data('accept-privacy')) {
                // If set, we are using the single checkbox for both.
                params['ACCEPTED_PRIVACY_POLICY'] = params['ACCEPTED_LOYALTY_TERMS'];
              }
            } else {
              params['PC_EMAIL_PROMOTIONS'] = '1';
              params['PC_EMAIL_PROMOTIONS_PRESENT'] = '1';
            }
            generic.jsonrpc.fetch({
              method: 'rpc.form',
              params: [params],
              onSuccess: function(jsonRpcResponse) {
                // send them to loyalty landing
                window.location.href = "/account/loyalty/index.tmpl";
              },
              onFailure: function(jsonRpcResponse) {
                // display error
                var errorObjectsArray = jsonRpcResponse.getMessages();
                var errListNode = $('#form--errors--loyalty-cta');
                generic.showErrors(errorObjectsArray, errListNode, $wrapper);
              }
            });
          });

          // no thanks
          $('.signin-loyalty-cta__link',$wrapper).click(function(event) {
            event.preventDefault();
            $.colorbox.close();
          });
        }

        $signInForm.add($registerForm).on('submit', function () {
          if (!(controlWraperDiv)) {
            /* Set the password field to what's in the visible password field if
            Show password is checked */
            var showPass = $showPass.is(':checked');
            if (showPass) {
              $pass.each(function () {
                $(this).val($(this).next().children().first().val());
              });
            }
          }

          // Set a cookie so we remember which form was submitted so we can
          // Launch the relevant confirmation overlay on the next page load
          var cookieVal = $(this).hasClass('sign-in-component__form--sign-in') ? 'signin-confirm' : 'register-confirm';
          $.cookie('signInOverlayState', cookieVal, {path: '/'});

          // EMEABEX-1448: CSRF token set in registration form if missing
          if ($csrfTokenTag.length && !$csrfTokenTag.value && csrfToken) {
            $csrfTokenTag.val(csrfToken);
          }
        });

        $('.signin-overlay__toggle-form a', $wrapper).on('click.signIn', function(event) {
          event.preventDefault();
          //$error_messages_list //not sure why this is here
          //this also hides Session Timeout message, since it's lumped into the Registration Form error message list
          var fromSessionTimeOut = window.location.href.indexOf('timeout=1') > 0;
          //show Session Timeout message if your session timed out
          $error_messages_list && !fromSessionTimeOut && $error_messages_list.hide();
          $wrapper.toggleClass('sign-in');
          $.colorbox.resize();
        });

        $($signInForm).on('submit', function(event) {
          $.cookie('showsignin', 1, {path: '/'});
        });

        $('.sign-in-component__close', $wrapper).on('click.signIn', function(event) {
          event.preventDefault();
          $.colorbox.close();
          if (signInOverlayState == 'register-confirm' && signedIn) {
            $( '.my-feed-drawer .drawer-formatter__trigger').trigger('mouseover');
          }
        });
        var openSignin = $.cookie('showsignin');
        if (openSignin) {
          $('.js-have-account a').eq(0).trigger('click');
          $.cookie('showsignin', null, {path: '/'});
        }
      } // /End initForm()

      if (isMobile && colorboxMobileValidate) {
        $triggerButton.on('click.signIn', function(event) {
          event.stopImmediatePropagation();
          $.cookie('showsignin', 1, {path: '/'});
          window.location.href = '/account/index.tmpl';
        });
      }

      $triggerButton.on('click.signIn', function(event) {
        if (settingsLoyaltyRegistrationSigninPage.il_rollout ||
          settingsLoyaltyRegistrationSigninPage.no_popup) {
          // Setting this cookie will show signin form on singin/signup page
          $.cookie('signInOverlayState', 'signin', { path: '/' });
          window.location.href = window.location.origin + '/account/index.tmpl';

          return;
        }
        $.cookie('showsignin', 1, {path: '/'});
        triggerOverlay(event, this);
        if (site.facebook) {
          site.facebook.init();
          $('.js-sign-in-component').addClass('fb-enabled');
        }
        $.colorbox.resize();
      });

      var loyalProfileFeature = Drupal.settings.globals_variables.loyalty_profile_feature;
      // Automatically launch the overlay if the cookie is set and we're not on
      // the dedicated sign in page.
      if (!isSignInPage) {
        // Disabling the sign in confirmation functionality since it was removed from the spec.
        // if ((signInOverlayState == 'register-confirm' || signInOverlayState == 'signin-confirm') && signedIn) {

        // For EL AU site, reg overlay should not show up while registrating a user, so disabled it by setting disable_reg_overlay as TRUE
        // and for rest of the sites it will show popup by negating the value of disable_reg_overlay.
        if (signInOverlayState === 'register-confirm' && signedIn && !Drupal.settings.globals_variables.disable_reg_overlay) {
          colorboxSettings.className += ' new-user-registration';
          _launchOverlay(forceReturn, returnURL);
        }
        // it will enable this popup for every 90 days once trigger_popup variable is true
        if (page_data.trigger_popup && loyalProfileFeature && loyalty && signedIn) {
          signInOverlayState = 'register-confirm';
          colorboxSettings.className += ' new-user-registration';
          _launchOverlay(forceReturn, returnURL);
        }

      }

      // Run initForm directly on the context. This will only really be useful
      // for the sign in page, where the form is already embedded.
      _initForm($('.sign-in-component', context));

      // Ensure this script doesn't break site if perlgem isn't running:
      if (typeof site != 'undefined' && typeof site.userInfoCookie != 'undefined') {
        // enabling to use first_name placeholder in CMS
        var $template = $('.page-utilities__account-text');
        if(firstName.length){
          var rendered = Mustache.render( $template.html(), { first_name: firstName } );
          $template.html( rendered );
        }else{
          var $userGreating = $('.user-greeting__name',$template);
          $userGreating.html('');
        }
        // bind to the recogized sign in link in the gnav
        $('.js-is_detected').on('click.signIn', function(event){ triggerOverlay(event, this) });
        // if user clicks on 'Sign out' link reset recognized user related cookies
        $('.sign-out-link').each(function(event) {
          var $signOutLink = $(this);
          var returnURL = window.location.pathname;
          var signOutURL = $signOutLink.attr('href');
          if (returnURL != "/checkout/confirm.tmpl" && !(/account\//.test(returnURL))) {
            signOutURL += '&success_url=' + returnURL;
          }
          $signOutLink.attr('href', signOutURL).on('click', function(event) {
            var domain = '.' + window.location.hostname.replace(/^.*(esteelauder\.)/, function(m, $1) {
              return $1;
            });
            var cookieObj = JSON.parse($.cookie('persistent_user_cookie', { path: '/', domain: domain }));
            cookieObj.first_name = null;
            $.cookie('persistent_user_cookie', JSON.stringify(cookieObj), { path: '/', domain: domain });
            $.cookie("persistent_user_last_purchase", null, { path: '/' });
            $.cookie('expandMyFeedTray', 0, { path: '/' });
            $.cookie('showsignin', 1, {path: '/'});
          });
        });
      }

      if (loyalProfileFeature) {
        var $rootElement = $('.sign-in-component__confirm--registration', context);
        var $phone2 = $('input[name="SMSPROMO_MOBILE_NUMBER"]', $rootElement).attr('name', 'PHONE2').attr('maxlength','12');
        var $smspromo = $('input[name="SMSPROMO_SMS_PROMOTIONS"]', $rootElement).attr('name', 'SMS_PROMOTIONS');
        $('.sign-in-component__confirm--registration', context).submit(function(e) {
          e.preventDefault();
          var $birth_month = $('select[name="BIRTH_MONTH"]', $rootElement);
          var $birth_day = $('select[name="BIRTH_DAY"]', $rootElement);
          var params = {};
          params['_SUBMIT'] = 'loyalty_profile';
          params['PHONE2'] = $phone2.val();
          params['SMS_PROMOTIONS'] = $smspromo && $smspromo.attr('checked') ? 1 : '';
          params['BIRTH_MONTH'] = $birth_month.val();
          params['BIRTH_DAY'] = $birth_day.val();
          generic.jsonrpc.fetch({
            method: 'rpc.form',
            params: [params],
            onSuccess: function(jsonRpcResponse) {
              var offerCode = Drupal.settings.lyl_lp_ocode || null;
              var paramObj = {'offer_code': offerCode, 'do_not_defer_messages': 1};
              generic.jsonrpc.fetch({
                method: 'offers.apply',
                params: [paramObj]
              });
              window.location.href = '/account/index.tmpl';
            },
            onFailure: function(jsonRpcResponse) {
              var errorObjectsArray = jsonRpcResponse.getMessages();
              var errListNode = $('.show_lyl_errors', context);
              generic.showErrors(errorObjectsArray, errListNode, $rootElement);
            }
          });
        });
      }
    }
  };
})(jQuery);
